<template>
    <div>
        <ef-dialog title="审核业务单" :visible.sync="show" top="60px" @confirm="handleReview">
            <el-form ref="reviewForm" :rules="rules" :model="form">
                <el-form-item label="审核人">
                    <el-input readonly v-model="$store.state.session.name" />
                </el-form-item>

                <el-form-item label="审核日期">
                    {{ formatDate(new Date()) }}
                </el-form-item>

                <el-form-item label="审核意见" prop="advice">
                    <template>
                        <el-radio v-model="form.advice" label="1" v-if="adviceNormal">同意</el-radio>
                        <el-radio v-model="form.advice" label="0" v-if="adviceNormal">拒绝</el-radio>
                        <el-radio v-model="form.towAdvice" label="1" v-if="advice == -1">反审核</el-radio>
                        <el-radio v-model="form.towAdvice" label="1" v-if="advice == -2">驳回</el-radio>
                    </template>
                </el-form-item>

                <el-form-item label="备注" prop="remark">
                    <el-input type="textarea" placeholder="请输入内容" v-model.trim="form.remark" />
                </el-form-item>
            </el-form>
        </ef-dialog>
    </div>
</template>

<script>
import EfDialog from 'components/EfDialog';

export default {
    name: 'EfReviewBiz',
    components: { EfDialog },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        baseUrl: {
            type: String,
            required: true,
        },
        codes: {
            type: Array,
            default: null,
        },
        advice: {
            type: Number, //-1 反审核 -2 驳货 默认审核
            default: 0,
        },
        requiredRemark: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            show: false,
            form: {
                codes: [],
                advice: '1',
                towAdvice: '1',
                remark: null,
            },
            rules: {
                advice: [{ required: true, message: '请选择审核意见', trigger: 'change' }],
                remark: [{ required: this.requiredRemark, message: '请填写备注', trigger: 'change' }],
            },
        };
    },
    watch: {
        visible: {
            handler(n, o) {
                this.show = n;
            },
            immediate: true,
        },
    },
    computed: {
        adviceNormal() {
            return this.advice != -1 && this.advice != -2;
        },
    },
    methods: {
        open(reviewBizArr) {
            if (reviewBizArr == null || reviewBizArr.length === 0) {
                this.$message.error('未选择单据');
                return false;
            }
            for (const e of reviewBizArr) {
                if (e.code == null || e.code === '') {
                    this.$message.error('未选择单据');
                    return false;
                }
                if (e.reviewStatus !== 0) {
                    this.$message.error('非待审核单据不能审核');
                    return false;
                }
            }
            this.show = true;
            this.form.codes = reviewBizArr.map((e) => e.code);
        },
        reviewAndRejectOpen(bizCode) {
            this.show = true;
            this.form.codes.push(bizCode);
        },
        handleReview() {
            if (this.codes) {
                this.form.codes = this.codes;
            }
            const _this = this;
            this.$refs.reviewForm.validate(async (valid) => {
                if (!valid) {
                    return false;
                }
                if (this.form.codes == null || this.form.codes.length === 0) {
                    this.$message.error('未选择单据');
                    return false;
                }
                if (this.form.codes.length === 1) {
                    this.form.codes = this.form.codes[0];
                }
                let rst;
                if (this.adviceNormal) {
                    rst = await this.$efApi.reviewApi.review(this.baseUrl, _this.form);
                    this.$message.success('审核成功');
                } else if (this.advice == -1) {
                    // 反审核
                    rst = await this.$efApi.reviewApi.backReview(this.baseUrl, {
                        codes: this.form.codes,
                        bizCode: this.form.codes,
                        advice: '1',
                        remark: this.form.remark,
                    });
                    this.$message.success('反审核成功');
                } else if (this.advice == -2) {
                    //驳回
                    rst = await this.$efApi.reviewApi.reject(this.baseUrl, {
                        codes: this.form.codes,
                        bizCode: this.form.codes,
                        advice: '1',
                        remark: this.form.remark,
                    });
                    this.$message.success('驳回成功');
                }
                this.$emit('completed', rst);
                this.show = false;
            });
        },
    },
};
</script>
